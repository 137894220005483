.message_container {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    align-items: center;
}


.left_time_info{
    font-size: 11px;
    margin-bottom: 28px !important;
}

.message_explain {
    white-space: pre-line;
    font-size: 12px;
    padding: 30px 0;
    line-height: 16px;
}

.spring_btn {
    width: 195px;
    height: 35px;
    font-size: 14px;
    background-color: #FFE8E7;
    border: 0.4px solid #7b7b7b;
    border-radius: 4px;
    font-family: SCDream4;
    /* color: #313131; */
    outline: 0;
    padding: 1px 1px;
    margin: 1px auto;
}

.spring_btn:hover{
    cursor: pointer;
}

/* 비활성화 상태일 때 */
.spring_btn:disabled {
    background-color: #d3d3d3 !important;
    color: #313131;         
    cursor: not-allowed;     
}

  /* 모달 내용 */
.my_modal_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 310px;
    /* height: 357px; */
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3); 
}

/* 닫기 버튼 */
.close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

/* 다음 버튼 */
.next_btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.item_box {
    width: 217px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 41px;
}

.message_item {
    height: fit-content;
}

.message_item:hover{
    cursor: pointer;
}

.message_item:nth-child(1){
    width: 40px;
}

.message_item:nth-child(2){
    width: 33px;
}

.message_item:nth-child(3){
    width: 29px;
}

.message_item:nth-child(4){
    width: 50px;
}

.form_group input, .form_group textarea {
    width: 199px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: none;
    font-size: 14px;
}

.form_group textarea {
    height: 88px;
}