.info_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2em 0;
}

.info_container {
    font-size: 16px;
}

.info_name_box{
    display: flex;
    justify-content: space-around;
    width: 310px;
    font-size: 38px;
    font-family: "Autography", sans-serif;
    align-items: center;
    margin: 0 auto;
}

.small_heart{
    width: 13px;
    height: 11px;
}

.info_date_box{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 50px;
    padding: 0.5em 0;
    font-family: "BLOVERLY", sans-serif;
}

.info_detail_name_box {
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    font-size: 14px;
    margin: 0 auto;
}

.info_detail_name_box span {
    display: flex;
}

.info_detail_name_box span p {
    padding: 0 0.5em;
}

.info_detail_date_box {
    display: flex;
    flex-direction: column;
    /* color: #313131; */
    font-size: 14px;
    margin-top: 25px;
}

.info_detail_date_box p{
    margin-top: 6px;
}